import React from 'react';
import BodyClassName from 'react-body-classname';
import { Loader } from 'rsuite';
import { Router, AppProvider } from '@airstudio/components';
import { Authenticator, Greetings, UsernameAttributes, ForgotPassword } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import {AppBar, Toolbar, Typography, CssBaseline} from '@material-ui/core';
import { createMuiTheme, makeStyles, Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {GroupTabs} from '../GroupTabs';

import * as Pages from '../../pages';
import {Logo} from '../Logo';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#fb8b1f',
    },
  },
  typography: {
    fontFamily: [
      'TypoPRO Iosevka Term',
    ].join(','),
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: '#000',
    color:  '#fb8b1f',
  },
  toolbar: {
    minHeight: 30,
  },
  typo: {
    fontSize: 10,
  },
  container: {
    height: 'calc(100% - 30px)',
  },
}));

const RootLoading: React.FC = () => {
  return (
    <div style={{textAlign: 'center', padding: 200}}>
      <Loader size="lg" />
    </div>
  );
};

export const Root: React.FC = () => {

  const classes = useStyles();

  const authModule = () => {
    return (
      <BodyClassName className="page--login">
        <>
          <div style={{textAlign: 'center', marginTop: 100}}>
            <Logo />
          </div>

          <Authenticator
            usernameAttributes={UsernameAttributes.EMAIL}
            hide={[Greetings, ForgotPassword]}
            theme={{
              sectionHeader: {
                display: 'none',
                fontSize: 24,
                fontFamily: '\'Playfair Display\', sans-serif',

              },
              formSection: {
                boxShadow: 'none',
              },
              a: {
                color: '#fb8b1f',
              },
              button: {
                backgroundColor: '#fb8b1f',
              },
              formContainer: {
                marginTop: '60px',
              },
            }}
            signUpConfig={{
              hideAllDefaults: true,
              signUpFields: [{
                label: 'Email',
                key: 'email',
                required: true,
                displayOrder: 1,
                type: 'string',
              }, {
                label: 'Password',
                key: 'password',
                required: true,
                displayOrder: 2,
                type: 'password',
              }],
            }}
          />
        </>
      </BodyClassName>
    );
  };

  return (
    <ThemeProvider theme={darkTheme} >
      <CssBaseline />
      <AppProvider authentication={authModule} loading={RootLoading}>
        <AppBar classes={{root: classes.appBar}} position="static">
          <Toolbar classes={{root: classes.toolbar}}>
            <Typography classes={{root: classes.typo}}>BrokerNetwork</Typography>
            <GroupTabs />
          </Toolbar>
        </AppBar>

        <div className={classes.container}>
          <Router.Switch>
            <Router.Redirect exact path="/" to="/spaces" />
            <Router.Route path="/groups/:id" component={Pages.Groups} />
            <Router.Route path="/me" component={Pages.Me} />
            <Router.Route component={Pages.NotFound} />
          </Router.Switch>
        </div>

      </AppProvider>
    </ThemeProvider>
  );
};
