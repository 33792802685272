import React, {useState} from 'react';
import {Tabs, Tab, makeStyles, Theme} from '@material-ui/core';
import {useResourceList} from '@airstudio/hooks';
import { Link } from 'react-router-dom';

export interface IGroupTabs {}

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    marginLeft: 30,
    minHeight: 30,
  },
  tab: {
    fontSize: theme.typography.pxToRem(10),
    minHeight: 30,
  },
  tabWrapper: {
    width: 75,
  },
}));

export const GroupTabs: React.FC<IGroupTabs> = (props: IGroupTabs) => {

  const {resources} = useResourceList({path: 'groups'});

  const [groupId, setGroupId] = useState('');
  const classes = useStyles();

  const handleGroupChange = (e: any, id: string) => {
    setGroupId(id);
  };

  return (
    <Tabs
      classes={{root: classes.tabs}}
      textColor="primary"
      value={groupId}
      onChange={handleGroupChange}
      indicatorColor="primary"
    >
      {resources.map((r) => (
        <Tab
          component={Link}
          key={r.id}
          classes={{
            root: classes.tab,
            wrapper: classes.tabWrapper,
          }}
          label={r.label}
          value={r.id}
          to={`/groups/${r.id}`}
        />
      ))}
    </Tabs>
  );
};
