import React, {useState} from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useResourceList } from '@airstudio/hooks';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

export interface IOptionGridProps {
  groupId: string;
}

export const OptionGrid: React.FC<IOptionGridProps> = (props: IOptionGridProps) => {

  const {groupId} = props;
  const { resources: options } = useResourceList({path: `groups/${groupId}/options`});

  const [, setGridApi] = useState(null);
  const [, setGridColumnApi] = useState(null);

  const handleGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.columnApi.autoSizeAllColumns();
  };

  return (
    <>
      <AgGridReact
        rowData={options}
        onGridReady={handleGridReady}
        groupSelectsChildren={true}
        defaultColDef={{
          sortable: true,
          resizable: true,
          filter: true,
        }}
        columnTypes={{
          money: {
            sortable: true,
            resizable: true,
            filter: true,
            valueFormatter: ({value}) => `£${parseFloat(value).toFixed(2)}`,
          },
          percentage: {
            sortable: true,
            resizable: true,
            filter: true,
            valueFormatter: ({value}: {value: string}) => `${(parseFloat(value) * 100).toFixed(1)}%`,
          },
        }}
      >
        <AgGridColumn headerName="Contract">
          <AgGridColumn
            editable
            singleClickEdit
            field="commodity"
            cellEditor="agRichSelectCellEditor"
            cellEditorParams={{
              values: [`coal`, 'power', 'carbon'],
            }}
          />
          <AgGridColumn
            editable
            singleClickEdit
            field="contract"
            cellEditor="agRichSelectCellEditor"
            cellEditorParams={(params: any) => {
              if (!params.data.commodity) { return {values: []}; }
              return {
                values: [
                  `FR`,
                  `IT`,
                ],
              };
            }}
          />
          <AgGridColumn
            editable
            singleClickEdit
            field="tenor"
            cellEditor="agRichSelectCellEditor"
            cellEditorParams={{
              values: ['2021-Q1', '2022-Q2'],
            }}
          />
          <AgGridColumn
            editable
            singleClickEdit
            cellEditor="agTextCellEditor"
            cellEditorParams={{
              placeholder: 'YYYY-MM-DD',
            }}
            field="expiry"

          />
        </AgGridColumn>

        <AgGridColumn headerName="Option">
          <AgGridColumn
            editable
            singleClickEdit
            field="rf"
            type="percentage"
          />
          <AgGridColumn
            editable
            singleClickEdit
            field="spot"
            type="money"
          />
          <AgGridColumn
            editable
            singleClickEdit
            field="strike"
            type="money"
          />
          <AgGridColumn
            field="type"
            editable
            singleClickEdit
            cellEditor="agRichSelectCellEditor"
            cellEditorParams={{
              values: ['PUT', 'CALL'],
            }}
          />
        </AgGridColumn>

        <AgGridColumn headerName="Bid">
          <AgGridColumn
            field="bidQuantity"
            headerName="Qty"
            groupId="bid"
          />
          <AgGridColumn
            field="bidPercentage"
            headerName="%"
            type="percentage"
          />
          <AgGridColumn field="bid" type="money" />
        </AgGridColumn>

        <AgGridColumn headerName="Ask">
          <AgGridColumn field="ask" type="money" />
          <AgGridColumn field="askPercentage" headerName="%" type="percentage" />
          <AgGridColumn field="askQuantity" headerName="Qty" />
        </AgGridColumn>

        <AgGridColumn headerName="Price">
          <AgGridColumn field="delta" />
          <AgGridColumn field="deltaBid" type="money" />
        </AgGridColumn>

      </AgGridReact>
    </>
  );
};
