import React from 'react';
import {useParams} from 'react-router-dom';

import {OptionGrid} from '../../components/OptionGrig';

export const Groups: React.FC = () => {
  const { id } = useParams<{id: string}>();

  return (
    <div className="ag-theme-alpine-dark" style={{ width: '100%', height: '100%', border: 0 }}>
      <OptionGrid groupId={id} />
    </div>
  );
};
